import React from 'react'
import ProgressBar from 'react-bootstrap/ProgressBar'
import { useStore } from '../../store'

const Progress = () => {
  const progress = useStore((state) => state.progress)
  return progress >= 0 ? <ProgressBar animated now={progress} /> : null
}

export { Progress }
