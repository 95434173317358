import React from 'react'
import { propTypes } from '../../helpers'
import PropTypes from 'prop-types'
import { useHistory, useParams } from 'react-router-dom'
import { Editor } from 'react-editor'
import { Button } from 'react-bootstrap'
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons'
import { ContentPanel, Icon, Slider, StoryGrid } from '../../components'
import { useDocuments } from '../../hooks'
import { DocumentBrowser } from '../documents/DocumentBrowser'

const StoryContent = ({
  stories,
  selectedStory,
  editorState,
  setEditorState,
  editing = false,
  changed = false,
  showFiles = false,
  onCloseFiles,
  draggable,
  onDragEnd
}) => {
  const { affiliationUrlSlug, categoryUrlSlug } = useParams()
  const history = useHistory()
  const { documents } = useDocuments()

  const onSelect = (story) =>
    history.push(`/${affiliationUrlSlug}/${categoryUrlSlug}/${story.id}`)

  const onClose = () =>
    history.push(`/${affiliationUrlSlug}/${categoryUrlSlug}`)

  return selectedStory ? (
    <>
      <ContentPanel className='story-panel'>
        {!editing && !changed ? (
          <Button variant='link' onClick={onClose}>
            Close <Icon icon={faTimesCircle} size='lg' />
          </Button>
        ) : null}
        <Editor
          readOnly={!editing}
          editorState={editorState}
          setEditorState={setEditorState}
          toolbar={{
            options: [
              'inline',
              'blockType',
              'fontSize',
              'list',
              'textAlign',
              'colorPicker',
              'link',
              'image',
              'remove',
              'history'
            ]
          }}
        />
      </ContentPanel>
      {editing && documents ? (
        <Slider visible={showFiles}>
          <Button variant='link' onClick={onCloseFiles}>
            Close <Icon icon={faTimesCircle} size='lg' />
          </Button>
          <DocumentBrowser documents={documents} />
        </Slider>
      ) : null}
    </>
  ) : (
    <StoryGrid {...{ stories, onSelect, draggable, onDragEnd }} />
  )
}

StoryContent.propTypes = {
  stories: propTypes.storyArrayPropType,
  selectedStory: propTypes.storyPropType,
  editing: PropTypes.bool,
  changed: PropTypes.bool,
  showFiles: PropTypes.bool,
  onCloseFiles: PropTypes.func,
  draggable: PropTypes.bool,
  onDragEnd: PropTypes.func
}

export { StoryContent }
