import React, { useEffect, useState } from 'react'
import { env, propTypes } from '../../helpers'
import TagManager from 'react-gtm-module'
import {
  DropdownAccordion,
  Toolbar,
  VisualizationFactory
} from '../../components'
import { useVisualization, useVisualizations } from '../../hooks'
import { useStore } from '../../store'
import styled from 'styled-components'

const VizualizationTitle = styled.div`
  font-size: 28px;
  padding-bottom: 0.5rem;
`

const pushGtmData = (category, action, label) => {
  env.gtmId &&
    TagManager.dataLayer({
      dataLayer: {
        event: 'gaEvent',
        eventCategory: category,
        eventAction: action,
        eventLabel: label
      }
    })
}

const VisualizationPage = ({ category }) => {
  const [selected, setSelected] = useState()
  const disabled = useStore((state) => state.disabled)
  const { visualizations } = useVisualizations(category.id)
  const { visualization } = useVisualization(selected && selected.id)

  useEffect(() => {
    const storedVisualization = JSON.parse(
      window.localStorage.getItem(`SELECTED_VISUALIZATION_${category.id}`)
    )
    const selectedVisualization =
      visualizations &&
      ((storedVisualization &&
        visualizations.find((viz) => viz.id === storedVisualization.id)) ||
        visualizations[0])
    if (selectedVisualization) {
      pushGtmData(
        `${category.name} dropdown`,
        'Default',
        selectedVisualization.name
      )
      setSelected(selectedVisualization)
    }
  }, [category.id, category.name, visualizations])

  const handleSelect = (item) => {
    const selectedVisualization = visualizations.find(
      (visualization) => visualization.id === item.id
    )
    window.localStorage.setItem(
      `SELECTED_VISUALIZATION_${category.id}`,
      JSON.stringify(selectedVisualization)
    )
    pushGtmData(
      `${category.name} dropdown`,
      'Click',
      selectedVisualization.name
    )
    setSelected(selectedVisualization)
  }

  return (
    <>
      <Toolbar title={category.name}>
        {visualizations ? (
          <DropdownAccordion
            id='visualizationSelect'
            title='Select'
            options={visualizations}
            selectedOption={selected}
            onOptionSelect={handleSelect}
            variant='link'
            disabled={disabled}
          />
        ) : null}
      </Toolbar>
      {selected ? (
        <VizualizationTitle>{selected.name}</VizualizationTitle>
      ) : null}
      {visualization ? (
        <VisualizationFactory visualization={visualization} />
      ) : null}
    </>
  )
}

VisualizationPage.propTypes = { category: propTypes.categoryPropType }

export { VisualizationPage }
