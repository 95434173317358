import React from 'react'
import { env, propTypes } from '../../helpers'
import { Container } from 'react-bootstrap'
import { ContentPanel, Toolbar } from '../../components'
import styled from 'styled-components'

const Calendar = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 75%;
  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
`

const CalendarPage = ({ category }) => (
  <Container className='resizing-container'>
    <Toolbar title={category.name} />
    <ContentPanel className='calendar-panel'>
      <Calendar>
        <iframe
          src={env.calendarUrl}
          title='calendar'
          frameBorder='0'
          scrolling='no'
        />
      </Calendar>
    </ContentPanel>
  </Container>
)

CalendarPage.propTypes = { category: propTypes.categoryPropType }

export { CalendarPage }
