import create from 'zustand'

let errorTimer, messageTimer, progressTimer
const PAGE_STATE = {
  DEFAULT: 'DEFAULT',
  EDIT: 'EDIT',
  READ: 'READ'
}
const PERMISSION = {
  UNASSIGNED: 'UNASSIGNED',
  ADMIN: 'ADMIN',
  EDIT: 'EDIT',
  VIEW: 'VIEW',
  DENIED: 'DENIED'
}

const useStore = create((set, get) => ({
  disabled: false,
  setDisabled: (disabled) => set(() => ({ disabled })),
  documentCategory: undefined,
  setDocumentCategory: (documentCategory) => set(() => ({ documentCategory })),
  error: '',
  setError: (error) => {
    clearTimeout(errorTimer)
    set(() => ({ error }))
    errorTimer = setTimeout(() => {
      set(() => ({ error: '' }))
    }, 30000)
  },
  imgSrcLogin: undefined,
  setImgSrcLogin: (imgSrcLogin) => set(() => ({ imgSrcLogin })),
  imgAltLogin: '',
  setImgAltLogin: (imgAltLogin) => set(() => ({ imgAltLogin })),
  imgSrcLogo: undefined,
  setImgSrcLogo: (imgSrcLogo) => set(() => ({ imgSrcLogo })),
  imgAltLogo: '',
  setImgAltLogo: (imgAltLogo) => set(() => ({ imgAltLogo })),
  imgSrcTour: undefined,
  setImgSrcTour: (imgSrcTour) => set(() => ({ imgSrcTour })),
  message: '',
  setMessage: (message) => {
    clearTimeout(messageTimer)
    set(() => ({ message }))
    messageTimer = setTimeout(() => {
      set(() => ({ message: '' }))
    }, 30000)
  },
  pageState: PAGE_STATE.DEFAULT,
  setPageState: (pageState) => set(() => ({ pageState })),
  permissions: [],
  setPermissions: (permissions) => set(() => ({ permissions })),
  progress: -1,
  progressStart: () => get().setProgress(20),
  progressEnd: () => get().setProgress(100),
  setProgress: (progress) => {
    clearTimeout(progressTimer)
    set(() => ({ progress }))
    if (progress >= 100) {
      progressTimer = setTimeout(() => {
        set(() => ({ progress: -1 }))
      }, 400)
    }
  },
  selectedAffiliation: undefined,
  setSelectedAffiliation: (selectedAffiliation) =>
    set(() => ({ selectedAffiliation }))
}))

export { PAGE_STATE, PERMISSION, useStore }
