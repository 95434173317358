import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { propTypes } from '../../helpers'
import { useParams } from 'react-router-dom'
import { Button, Col, Container, Image, Row } from 'react-bootstrap'
import { ContentPanel } from '../../components'
import { StoryContent } from '../stories/StoryContent'
import { useStories } from '../../hooks'
import { rawToState } from 'react-editor'
import { useStore } from '../../store'

const LoginPage = ({ category, onLogin }) => {
  const { id } = useParams()
  const setError = useStore((state) => state.setError)
  const imgSrcLogin = useStore((state) => state.imgSrcLogin)
  const imgAltLogin = useStore((state) => state.imgAltLogin)
  const imgSrcLogo = useStore((state) => state.imgSrcLogo)
  const imgAltLogo = useStore((state) => state.imgAltLogo)
  const { stories } = useStories(category.id)
  const [selectedStory, setSelectedStory] = useState()

  useEffect(() => {
    if (stories && id) {
      const story = stories.find((it) => it.id === Number(id))
      if (story) {
        setSelectedStory(story)
        window.scrollTo(0, 0)
      } else {
        setError(`Story not found`)
      }
    } else {
      setSelectedStory(null)
    }
  }, [id, setError, setSelectedStory, stories])

  const storyProps = {
    stories,
    selectedStory,
    editorState: selectedStory && rawToState(selectedStory.content)
  }
  return (
    <Container className='resizing-container' fluid={!selectedStory}>
      <ContentPanel className='login-panel'>
        <Row>
          <Col
            className='pb-3'
            xs={{ span: 12, order: 1 }}
            md={{ span: 6, order: 1 }}
          >
            <Row className='h-75 justify-content-center'>
              <h1 className='w-100'>
                <Image
                  className='login-logo-img'
                  fluid
                  src={imgSrcLogo}
                  alt={imgAltLogo}
                />
              </h1>
            </Row>
            <Row className='justify-content-center'>
              <Button variant='primary' onClick={onLogin}>
                Log In
              </Button>
            </Row>
          </Col>
          <Col
            className='login-col-img'
            xs={{ span: 12, order: 0 }}
            md={{ span: 6, order: 2 }}
          >
            <Image src={imgSrcLogin} alt={imgAltLogin} />
          </Col>
        </Row>
      </ContentPanel>
      {stories ? <StoryContent {...storyProps} /> : null}
    </Container>
  )
}

LoginPage.propTypes = {
  category: propTypes.categoryPropType,
  onLogin: PropTypes.func.isRequired
}

export { LoginPage }
