import React, { useEffect, useState } from 'react'
import { propTypes } from '../../helpers'
import { Container } from 'react-bootstrap'
import {
  AffiliationForm,
  ContentPanel,
  DropdownAccordion,
  Toolbar
} from '../../components'
import { useStore } from '../../store'
import styled from 'styled-components'
import { UsersList, UserType } from './UsersList'
import { useAdminCategories, useSaveAffiliationMutation } from '../../hooks'

const NEW_USERS = { id: 1, name: 'New User Requests' }
const EXISTING_USERS = { id: 2, name: 'Manage Users' }
const ALL_USERS = { id: 3, name: 'Search Among All Users' }
const MANAGE_PORTAL = { id: 4, name: 'Manage Portal Interface' }
const adminOptions = [NEW_USERS, EXISTING_USERS, ALL_USERS, MANAGE_PORTAL]

const Title = styled.div`
  font-size: 28px;
  padding-bottom: 0.5rem;
`

const AdminPage = ({ category }) => {
  const [userType, setUserType] = useState(UserType.New)
  const [selected, setSelected] = useState(adminOptions[0])
  const selectedAffiliation = useStore((state) => state.selectedAffiliation)
  const { categories } = useAdminCategories()

  const { mutate: saveAffiliation } = useSaveAffiliationMutation()

  const handleSubmit = (data) => saveAffiliation({ data })

  useEffect(() => {
    switch (selected) {
      case NEW_USERS:
        setUserType(UserType.New)
        break
      case EXISTING_USERS:
        setUserType(UserType.Existing)
        break
      case ALL_USERS:
        setUserType(UserType.All)
        break
      default:
    }
  }, [selected])

  return (
    <Container className='resizing-container'>
      <Toolbar title={`${selectedAffiliation.name} ${category.name}`}>
        <DropdownAccordion
          id='adminSelect'
          title='Select'
          options={adminOptions}
          selectedOption={selected}
          onOptionSelect={setSelected}
          variant='link'
        />
      </Toolbar>
      <ContentPanel>
        <Title>{selected.name}</Title>
        {selected === MANAGE_PORTAL ? (
          <AffiliationForm
            formData={{ ...selectedAffiliation, categories }}
            onSubmit={handleSubmit}
          />
        ) : (
          <UsersList userType={userType} />
        )}
      </ContentPanel>
    </Container>
  )
}

AdminPage.propTypes = { category: propTypes.categoryPropType }

export { AdminPage }
