import React, { useCallback, useEffect, useState } from 'react'
import { propTypes } from '../../helpers'
import { Button, ButtonGroup, Container } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEdit,
  faEye,
  faFile,
  faPlusCircle,
  faSave,
  faTrash,
  faTimesCircle
} from '@fortawesome/free-solid-svg-icons'
import { ConfirmPopover, Toolbar } from '../../components'
import { StoryContent } from './StoryContent'
import {
  useDeleteStoryMutation,
  useSortStoryMutation,
  useStories,
  useSaveStoryMutation
} from '../../hooks'
import { createEmptyState, rawToState, stateToRaw } from 'react-editor'
import { useHistory, useParams } from 'react-router-dom'
import { PAGE_STATE, PERMISSION, useStore } from '../../store'

const StoryPage = ({ category }) => {
  const { affiliationUrlSlug, categoryUrlSlug, id } = useParams()
  const history = useHistory()
  const [selectedStory, setSelectedStory] = useState()
  const [editorState, setEditorState] = useState()
  const [initialEditorState, setInitialEditorState] = useState()
  const [editing, setEditing] = useState()
  const [showFiles, setShowFiles] = useState()
  const disabled = useStore((state) => state.disabled)
  const setDisabled = useStore((state) => state.setDisabled)
  const documentCategory = useStore((state) => state.documentCategory)
  const setError = useStore((state) => state.setError)
  const setPageState = useStore((state) => state.setPageState)
  const permissions = useStore((state) => state.permissions)

  const { stories } = useStories(category.id)
  const { mutate: saveStory } = useSaveStoryMutation()
  const { mutate: deleteStory } = useDeleteStoryMutation()
  const { mutate: sortStory } = useSortStoryMutation()

  useEffect(() => {
    if (stories && id) {
      const story = stories.find((it) => it.id === Number(id))
      if (story) {
        setSelectedStory(story)
        window.scrollTo(0, 0)
      } else {
        setError(`Story not found`)
      }
    } else {
      setSelectedStory(null)
    }
  }, [id, setError, setSelectedStory, stories])

  useEffect(() => {
    setPageState(
      selectedStory
        ? editing
          ? PAGE_STATE.EDIT
          : PAGE_STATE.READ
        : PAGE_STATE.DEFAULT
    )
  }, [editing, selectedStory, setPageState])

  const initEditorState = useCallback(() => {
    const newState = rawToState(selectedStory.content)
    setInitialEditorState(newState)
    setEditorState(newState)
  }, [selectedStory])

  useEffect(() => {
    selectedStory && initEditorState()
  }, [initEditorState, selectedStory])

  useEffect(() => {
    setDisabled(
      editorState &&
        initialEditorState.getCurrentContent() !==
          editorState.getCurrentContent()
    )
  }, [editorState, initialEditorState, setDisabled])

  const onAdd = () => {
    setSelectedStory({
      categoryId: category.id,
      content: stateToRaw(createEmptyState()),
      id: null
    })
    setEditing(true)
  }

  const onSave = () =>
    saveStory(
      {
        categoryId: category.id,
        storyId: selectedStory.id,
        content: stateToRaw(editorState)
      },
      {
        onSuccess: (data) => {
          setEditing(false)
          setSelectedStory(data)
          history.push(`/${affiliationUrlSlug}/${categoryUrlSlug}/${data.id}`)
        }
      }
    )

  const onCancel = () => {
    onToggleEdit()
    if (selectedStory.id) {
      initEditorState()
    } else {
      setSelectedStory(null)
    }
  }
  const onToggleFiles = () => setShowFiles(!showFiles)
  const onToggleEdit = () => setEditing(!editing)

  const onDelete = () =>
    deleteStory(
      { categoryId: category.id, storyId: selectedStory.id },
      {
        onSuccess: () => {
          history.push(`/${affiliationUrlSlug}/${categoryUrlSlug}`)
          setSelectedStory(null)
        }
      }
    )

  const handleDragEnd = ({ active, over }) => {
    const story = stories.find((story) => story.id === over.id)
    sortStory({
      categoryId: category.id,
      storyId: active.id,
      order: story.orderNumber
    })
  }

  const storyProps = {
    stories,
    selectedStory,
    editorState,
    setEditorState,
    editing,
    changed: disabled,
    showFiles,
    onCloseFiles: onToggleFiles,
    draggable: permissions.includes(PERMISSION.EDIT),
    onDragEnd: handleDragEnd
  }
  return (
    <Container className='resizing-container' fluid={!selectedStory}>
      <Toolbar title={category.name}>
        {permissions.includes(PERMISSION.EDIT) ? (
          selectedStory ? (
            editing ? (
              <ButtonGroup>
                <Button onClick={onSave} title='Publish' variant='link'>
                  {disabled ? '* ' : null}
                  Publish <FontAwesomeIcon icon={faSave} size='sm' />
                </Button>
                <Button onClick={onCancel} title='Discard' variant='link'>
                  Discard <FontAwesomeIcon icon={faTimesCircle} size='sm' />
                </Button>
                {documentCategory ? (
                  <Button
                    onClick={onToggleFiles}
                    aria-label='Files'
                    title='Show the slide-out document’s file browser.'
                    variant='link'
                  >
                    Files <FontAwesomeIcon icon={faFile} size='sm' />
                  </Button>
                ) : null}
                <Button
                  onClick={onToggleEdit}
                  aria-label='Preview'
                  title='Show a preview of what the page will look like to viewers.'
                  variant='link'
                >
                  Preview <FontAwesomeIcon icon={faEye} size='sm' />
                </Button>
              </ButtonGroup>
            ) : (
              <ButtonGroup>
                <Button
                  onClick={onToggleEdit}
                  aria-label='Edit'
                  title='Edit Story'
                  variant='link'
                >
                  {disabled ? '* ' : null}Edit{' '}
                  <FontAwesomeIcon icon={faEdit} size='sm' />
                </Button>
                <ConfirmPopover id='delete-story' onConfirm={onDelete}>
                  <Button
                    aria-label='Delete'
                    title='Delete Story'
                    variant='link'
                  >
                    {disabled ? '* ' : null}Delete{' '}
                    <FontAwesomeIcon icon={faTrash} size='sm' />
                  </Button>
                </ConfirmPopover>
              </ButtonGroup>
            )
          ) : (
            <Button
              onClick={onAdd}
              aria-label='Add Story'
              title='Create Story'
              variant='link'
            >
              Add Story <FontAwesomeIcon icon={faPlusCircle} size='sm' />
            </Button>
          )
        ) : null}
      </Toolbar>
      {stories ? <StoryContent {...storyProps} /> : null}
    </Container>
  )
}

StoryPage.propTypes = { category: propTypes.categoryPropType }

export { StoryPage }
