import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Col, Container } from 'react-bootstrap'
import { ContentPanel, MultiSelect, Toolbar } from '../../components'
import styled from 'styled-components'
import { useAccessRequests, useSaveAccessRequestsMutation } from '../../hooks'

const SubmitContainer = styled.div`
  padding-top: 280px;
  text-align: center;
`

const RequestAccessPage = () => {
  const [selected, setSelected] = useState([])
  const history = useHistory()
  const { options, selected: preselected } = useAccessRequests()
  const { mutate: saveAccessRequests } = useSaveAccessRequestsMutation()

  useEffect(() => {
    preselected && setSelected(preselected)
  }, [preselected])

  const onSubmit = () =>
    saveAccessRequests(
      {
        accessRequests: selected
          .filter((request) => request.disabled !== true)
          .map((item) => ({ id: item.id }))
      },
      {
        onSuccess: () => history.push('/')
      }
    )

  return (
    <Container className='resizing-container'>
      <Toolbar title='Account' />
      <ContentPanel>
        <Col lg={{ span: 6, offset: 3 }}>
          <h1 className='text-center'>Request Access</h1>
          <p className='text-center lead'>
            Please select the groups that you need access to. A confirmation
            message will be sent to your email address once your access has been
            activated
          </p>
          {options ? (
            <MultiSelect
              labelledBy='multi-select-label'
              options={options}
              selected={selected}
              onChange={setSelected}
            />
          ) : null}
          <SubmitContainer>
            <Button
              variant='secondary'
              disabled={preselected && preselected.length === selected.length}
              onClick={onSubmit}
            >
              Submit
            </Button>
          </SubmitContainer>
        </Col>
      </ContentPanel>
    </Container>
  )
}

export { RequestAccessPage }
