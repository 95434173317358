import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'react-bootstrap'
import { PanelGrid } from './PanelGrid'
import { Editor, rawToState } from 'react-editor'

const StoryGrid = ({ stories, onSelect, draggable, onDragEnd }) => (
  <PanelGrid
    className='story-grid'
    items={stories}
    renderItem={(story) => (
      <>
        <Editor readOnly editorState={rawToState(story.content)} />
        <Button
          variant='outline-secondary'
          onKeyDown={(event) => {
            if (event.key === 'Enter' || event.key === ' ') {
              onSelect(story)
              event.stopPropagation()
            }
          }}
          onClick={() => {
            onSelect(story)
          }}
        >
          Read More
        </Button>
      </>
    )}
    draggable={draggable}
    onDragEnd={onDragEnd}
  />
)

StoryGrid.propTypes = {
  stories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      content: PropTypes.string.isRequired
    })
  ),
  onSelect: PropTypes.func.isRequired,
  draggable: PropTypes.bool,
  onDragEnd: PropTypes.func
}

export { StoryGrid }
